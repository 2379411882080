import {
	calculateRisk,
	calculateToWinFromRisk,
	calculateWin,
} from 'services/Betslip'
import { ERROR_MESSAGES, MULTIPLES, validInputWager } from './Messages/messages'
import { isEmpty, isNil, get } from 'lodash'
import {
	ODDS_FORMAT,
	WIN_RISK_TYPE,
	BET_TYPE,
	WAGER_MYBET_MAP,
	ODDS_TYPE,
	BET_LOCATION_TRACKING,
} from 'constants/common'
import Decimal from 'decimal.js'
import PreciseOdds from 'apps/types/PreciseOdds'
import Session from 'apps/types/Session'
import localeInstance from 'apps/types/Locale'

export const ROUND_ROBIN = {
	Parlay: 1,
	TwoLegRoundRobin: 2,
	ThreeLegRoundRobin: 3,
	FourLegRoundRobin: 4,
	FiveLegRoundRobin: 5,
	SixLegRoundRobin: 6,
	SevenLegRoundRobin: 7,
	EightLegRoundRobin: 8,
}

export const MODAL_PORTAL_ID = {
	quickBet: 'quick-bet-portal',
}

export const BET_SLIP_DISPLAY = {
	unified: 'unified',
	tabs: 'tabs',
	quickBet: 'quickBet',
}

export const BET_SLIP_TYPE = {
	SINGLES: 'SINGLES',
	MULTIPLES: 'MULTIPLES',
	TEASERS: 'TEASERS',
}

export const WAGER_TYPE = {
	stake: 'RISK',
	win: 'WIN',
}

export const MAP_WAGER = {
	RISK: 'stake',
	WIN: 'win',
}

export const MESSAGES_NOTIFY_MULTIPLES = [
	MULTIPLES.NOT_ALL_AVAILABLE,
	ERROR_MESSAGES.ODDS_CHANGE,
	ERROR_MESSAGES.MAX_PICKS,
	ERROR_MESSAGES.LINE_CHANGED,
]

export const PLACED_BET_WARNING = [ERROR_MESSAGES.LINE_CHANGED]

export const PLACED_BET_ERROR = [
	ERROR_MESSAGES.ALL_BETTING_CLOSED,
	ERROR_MESSAGES.ERROR3105,
	ERROR_MESSAGES.SUSPENDED_LINE,
	ERROR_MESSAGES.INSUFFICIENT_FUNDS,
	ERROR_MESSAGES.B2B_INTERNAL_ERROR,
	ERROR_MESSAGES.BETTING_BUDGET_EXCEEDED,
	MULTIPLES.ROUND_ROBIN_DISALLOWED,
]

export const DENY_SELECTIONS_ERROR = [
	ERROR_MESSAGES.ERROR1004,
	ERROR_MESSAGES.ERROR1005,
	ERROR_MESSAGES.UNAVAILABLE,
	ERROR_MESSAGES.INVALID_BET_ACCEPTANCE_TYPE,
	ERROR_MESSAGES.EVENT_OFFLINE,
	ERROR_MESSAGES.INSUFFICIENT_FUNDS,
]

export const SELECTION_DETAIL_ERROR = [
	ERROR_MESSAGES.ALL_BETTING_CLOSED,
	ERROR_MESSAGES.INSUFFICIENT_FUNDS,
]

export const DECIMAL_ODDS_FORMAT = {
	[ODDS_TYPE.AMERICAN]: 0,
	[ODDS_TYPE.DECIMAL]: 3,
	[ODDS_TYPE.HONG_KONG]: 3,
	[ODDS_TYPE.MALAY]: 3,
	[ODDS_TYPE.INDONESIAN]: 3,
}

export const calculateRoundRobinOptions = (selectionCount, groupBet) => {
	const factorial1To8 = [1, 1, 2, 6, 24, 120, 720, 5040, 40320]
	return (
		factorial1To8[selectionCount] /
		(factorial1To8[groupBet] * factorial1To8[selectionCount - groupBet])
	)
}

const randomUUID = () =>
	Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)

export const generateUUID = () => {
	const length = 5
	const uuid = Array.from(Array(length), (_, index) => {
		if (index === 0) {
			return randomUUID() + randomUUID()
		}

		if (index === length - 1) {
			return randomUUID() + randomUUID() + randomUUID()
		}

		return randomUUID()
	})

	return uuid.join('-')
}

export const getParamsSingleSelection = (selections, oddsFormat) =>
	selections.map((selection) => {
		return {
			oddsFormat,
			oddsId: selection.oddsId,
			oddsSelectionsType: selection.oddsSelectionsType,
			selectionId: selection.selectionId,
		}
	})

export const getParamsParlaySelection = (selections, oddsFormat) =>
	selections.map((selection) => {
		return {
			oddsFormat,
			oddsId: selection.oddsId,
			oddsSelectionsType: selection.oddsSelectionsType,
			selectionId: selection.selectionId,
		}
	})

export const combineSelection = (localSelections, selections = []) =>
	localSelections.map((selection) => {
		const findSelection = selections.find(
			(sel) => selection.oddsId === sel.oddsId,
		)
		if (
			findSelection &&
			(findSelection.selection || findSelection.selectionId) &&
			findSelection.sportId
		) {
			return {
				...selection,
				...findSelection,
			}
		}
		return {
			...findSelection,
			...selection,
		}
	})

export const calculateParlayTotalStake = (
	roundRobinOptions = [],
	optionRoundRobin,
	stake,
	oddsFormat,
) => {
	if (!stake || roundRobinOptions.length === 0) {
		return {
			stake: 0,
			win: 0,
		}
	}

	const findRoundRobin = roundRobinOptions.find((option) =>
		parseInt(optionRoundRobin) === ROUND_ROBIN.Parlay
			? option.roundRobinOption === 'Parlay'
			: option.rroLegs === parseInt(optionRoundRobin),
	)
	const win = calculateToWinFromRisk(stake, findRoundRobin.odds, oddsFormat)
	return {
		stake: parseFloat(stake) * findRoundRobin.possibleWagers,
		win: parseFloat(win),
	}
}

export const formatCurrencyDecimal = (number, attachDigits = true) =>
	(Math.round(number * 100) / 100).toLocaleString(
		undefined,
		attachDigits
			? {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
			  }
			: {},
	)

export const checkValidSelections = (oddsFormat, selections, tracking = {}) => {
	const validSelections = selections.reduce((result, selection) => {
		const {
			odds,
			wager,
			minStakeRisk,
			maxStakeRisk,
			minStakeWin,
			maxStakeWin,
			oddsId,
			status,
			selectionId,
			oddsSelectionsType,
			winRiskStake,
			betLocationTracking,
		} = selection
		const negativeOdds =
			ODDS_FORMAT.MY === ODDS_FORMAT[oddsFormat] && parseFloat(odds) < 0
		if (
			!wager ||
			!minStakeRisk ||
			!maxStakeRisk ||
			validInputWager(
				negativeOdds ? minStakeWin : minStakeRisk,
				negativeOdds ? maxStakeWin : maxStakeRisk,
				getWagerSelection(selection, oddsFormat, negativeOdds),
			) !== ''
		) {
			return result
		}

		if (
			isNil(status) ||
			[ERROR_MESSAGES.ODDS_CHANGE, ERROR_MESSAGES.OK].includes(status)
		) {
			const item = {
				odds,
				oddsId,
				selectionId,
				stake: parseFloat(wager),
				winRiskStake: winRiskStake || WIN_RISK_TYPE.RISK,
				wagerType: oddsSelectionsType,
				uniqueRequestId: generateUUID(),
			}
			if (window.env.enableBetLocationTracking) {
				const preferences = Session.getPreferences()
				item.betLocationTracking = {
					...betLocationTracking,
					...tracking,
					language: localeInstance.getLanguage(),
					timeZone: preferences.timeZoneId,
					marketType: get(
						preferences,
						'marketType',
						BET_LOCATION_TRACKING.UNKNOWN,
					),
					eventSorting: get(
						preferences,
						'eventSorting',
						BET_LOCATION_TRACKING.UNKNOWN,
					),
					pageType: get(preferences, 'pageType', BET_LOCATION_TRACKING.UNKNOWN),
					defaultPage: get(
						preferences,
						'defaultPage',
						BET_LOCATION_TRACKING.UNKNOWN,
					),
				}
			}
			result.push(item)
		}
		return result
	}, [])
	return {
		acceptBetterOdds: get(Session.getPreferences(), 'acceptBetterOdds', false),
		oddsFormat: ODDS_FORMAT[oddsFormat],
		selections: validSelections,
	}
}

export const getWagerSelection = (selection, oddsFormat, isForceRisk = false) =>
	parseFloat(
		selection.winRiskStake === WIN_RISK_TYPE.RISK || isForceRisk
			? selection.wager
			: parseFloat(selection.wager) === selection.maxStakeWin
			? selection.maxStakeRisk
			: calculateRisk(
					selection.wager,
					selection.odds,
					ODDS_FORMAT[oddsFormat],
					WIN_RISK_TYPE.WIN,
					selection.betType,
			  ),
	)

export const getBetSingles = (bet, selections) => {
	const selectionData = selections.find(
		(selection) => selection.oddsId === bet.oddsId,
	)
	const { specialName } = bet.psBetVO
	const betType = selectionData.isSpecial
		? BET_TYPE[bet.psBetVO.betType]
		: selectionData.betType

	return {
		bets: {
			betId: bet.betId,
			odds: bet.odds,
			oddsId: bet.oddsId,
			selectionId: bet.selectionId,
			wagerId: bet.wagerId,
			totalStake: bet.psBetVO.risk,
			totalWin: bet.psBetVO.win,
		},
		betSelections: {
			...selectionData,
			betType,
			specialName,
			odds: bet.odds,
			oddsFormat: ODDS_FORMAT[bet.psBetVO.oddsFormat],
		},
	}
}

export const getBetPending = (bet, selections) => {
	const _selection = selections.find(
		(selection) => selection.oddsId === bet.oddsId,
	)
	return {
		bets: {
			betId: bet.betId,
			odds: bet.odds,
			oddsId: bet.oddsId,
			selectionId: bet.selectionId,
			wagerId: bet.wagerId,
		},
		betSelections: {
			..._selection,
			odds: bet.odds,
		},
	}
}

export const getBetSuccess = (selection, accepted) => ({
	bets: {
		...selection.bets,
		betId: accepted[WAGER_MYBET_MAP.wagerId],
		totalStake: accepted[WAGER_MYBET_MAP.toRisk],
		totalWin: accepted[WAGER_MYBET_MAP.toWin],
	},
	betSelections: selection.betSelections,
})

export const translatedLocalSelections = (selections, singles) =>
	selections.map((selection) => {
		const findSelection = singles.find(
			(single) => single.oddsId === selection.oddsId,
		)
		const participants = selection.participants?.map((p) => {
			return {
				...p,
				name:
					p.type === 'HOME' ? findSelection.homeTeam : findSelection.awayTeam,
			}
		})
		const isOutright = findSelection.betType === BET_TYPE.OUTRIGHT
		return {
			...selection,
			leagueName: isOutright ? findSelection.leagueName : findSelection.league,
			participants: participants,
			specialName: selection.specialName ? findSelection.eventName : null,
			contestantName:
				isOutright || findSelection.isSpecial
					? findSelection.contestantName
					: null,
		}
	})

export const attachMoreFromState = (newSelection, oldSelection, oddsFormat) =>
	newSelection.map((selection) => {
		const old = oldSelection.find((o) => o.oddsId === selection.oddsId)
		/**
		 * If status = ODD_CHANGES & selection.odds === cache.odds
		 * -> Keep previous old odds of cache
		 */
		let oldOdds = null
		if (old) {
			/**
			 *
			 * A (EU) -> B (EU) -> C (AM) -> D (AM)
			 * ----------------------------> E (EU)
			 * ----------------------------> F (HK)
			 *
			 */
			oldOdds =
				old.oddsFormat === oddsFormat
					? old.odds
					: old.oddsFormat === old.oldOddsFormat ||
					  old.oldOddsFormat === oddsFormat
					? old.oldOdds
					: old.odds
		}

		return {
			...selection,
			oddsFormat: oddsFormat,
			oldOdds: oldOdds,
			oldOddsFormat: old ? old.oddsFormat : null,
		}
	})

export const attachMoreParlayFromState = (
	newSelection,
	oldSelection,
	oddsFormat,
) => {
	/**
	 * If status = ODD_CHANGES & selection.odds === cache.odds
	 * -> Keep previous old odds of cache
	 */

	const legs = newSelection.legs.reduce((result, item) => {
		if (!oldSelection?.legs) {
			return result
		}
		const findLeg = oldSelection.legs.find((leg) => {
			const _leg = leg.selectionId.split('|').map(Number)
			const selection = item.selectionId.split('|').map(Number)
			return JSON.stringify(_leg) === JSON.stringify(selection)
		})

		result.push({
			...item,
			oldOdds:
				oldSelection.oddsFormat === oddsFormat
					? findLeg.odds
					: oldSelection.oddsFormat === oldSelection.oldOddsFormat ||
					  oldSelection.oldOddsFormat === oddsFormat
					? findLeg.oldOdds
					: findLeg.odds,
		})
		return result
	}, [])

	return {
		...newSelection,
		oddsFormat: oddsFormat,
		legs: legs,
		oldOdds: oldSelection
			? newSelection.odds === oldSelection.odds
				? null
				: oldSelection.odds
			: null,
		oldOddsFormat: oldSelection ? oldSelection.oddsFormat : null,
	}
}

export const combineMultiples = (selections, legs = []) => {
	if (!legs || legs.length === 0) {
		return selections
	}

	return selections.reduce((result, item) => {
		const findLeg = legs.find((leg) => {
			const _leg = leg.selectionId.split('|').map(Number)
			const selection = item.selectionId.split('|').map(Number)
			return JSON.stringify(_leg) === JSON.stringify(selection)
		})

		if (findLeg && findLeg?.odds) {
			if (
				findLeg?.legStatus === ERROR_MESSAGES.ODDS_CHANGE &&
				parseFloat(findLeg.odds) !== parseFloat(item.odds)
			) {
				result.push({
					...item,
					legStatus: findLeg.legStatus,
					oldOdds: findLeg.oldOdds,
					odds: findLeg.odds,
					parlayErrorCode: findLeg.errorCode,
				})

				return result
			}

			result.push({
				...item,
				odds: findLeg.odds,
				parlayErrorCode: findLeg.errorCode,
			})
			return result
		}

		result.push({ ...item, parlayErrorCode: findLeg?.errorCode })
		return result
	}, [])
}

export const formatWager = (wager) =>
	new Decimal(Number(wager).toFixed(2)).toNumber()

export const format2Decimal = (value, acceptedZero = false) =>
	isNaN(Number(value)) || (Number(value) === 0 && !acceptedZero)
		? ''
		: Number(value).toFixed(2)

// this is used from a fallback when there's no bet quote
// to give use an exact price.
export const calculatePriceFromSelections = (selections, oddsFormat) => {
	if (!selections || isEmpty(selections)) {
		return 0
	}

	const calculatePrice = selections.reduce((acc, item) => {
		let instance = new PreciseOdds(item.odds, item.oddsFormat)
		const dec =
			item.oddsFormat !== ODDS_FORMAT.DECIMAL
				? instance.convertTo(ODDS_FORMAT.DECIMAL)
				: instance
		return acc * dec.rounded()
	}, 1)

	const odds = new PreciseOdds(calculatePrice, ODDS_FORMAT.DECIMAL).convertTo(
		ODDS_FORMAT[oddsFormat],
	)
	return ODDS_FORMAT[oddsFormat] !== ODDS_FORMAT.AMERICAN
		? odds.value.toFixed(3, Decimal.ROUND_DOWN)
		: odds.rounded()
}

export const formatSelectionId = (selectionId, oddsSelectionsType) => {
	if (oddsSelectionsType === 'OUTRIGHT') {
		return selectionId
			.split('|')
			.filter((item, index) => !(index === 1 && Number(item) === 0))
			.map(Number)
			.join('|')
	}

	const selection = selectionId.split('|')
	if (selection.length > 8) {
		selection.splice(1, 1)
	}
	return selection.map(Number).join('|')
}

export const parseOddsAcceptedBet = (selection, currentOddsFormat) => {
	const isMoneyLine = Number(selection.betType) === BET_TYPE.THE_1X2

	if (isMoneyLine) {
		const _oddsFormat =
			selection.oddsFormat === ODDS_FORMAT.AMERICAN &&
			currentOddsFormat !== ODDS_TYPE.AMERICAN
				? ODDS_TYPE.DECIMAL
				: currentOddsFormat
		return !(
			[ODDS_TYPE.MALAY, ODDS_TYPE.HONG_KONG].includes(currentOddsFormat) &&
			selection.oddsFormat === ODDS_FORMAT.DECIMAL &&
			currentOddsFormat !== ODDS_TYPE.AMERICAN
		)
			? calculatePriceFromSelections([selection], _oddsFormat)
			: selection.odds
	}

	return calculatePriceFromSelections([selection], currentOddsFormat)
}

export const calculateWager = ({
	wager,
	betType,
	odds,
	oddsFormat,
	maxStakeRisk,
	maxStakeWin,
	winRiskStake = WIN_RISK_TYPE.RISK,
}) => {
	let stake = 0
	let win = 0
	if (
		(parseFloat(wager) === maxStakeRisk &&
			winRiskStake === WIN_RISK_TYPE.RISK) ||
		(parseFloat(wager) === maxStakeWin && winRiskStake === WIN_RISK_TYPE.WIN)
	) {
		stake = format2Decimal(maxStakeRisk)
		win = format2Decimal(maxStakeWin)
	} else if (winRiskStake === WIN_RISK_TYPE.WIN) {
		win = wager
		stake = calculateRisk(wager, odds, oddsFormat, WIN_RISK_TYPE.WIN, betType)
	} else {
		stake = wager
		win = calculateWin(wager, odds, oddsFormat, WIN_RISK_TYPE.RISK, betType)
	}
	return {
		win,
		stake,
	}
}

export const handleDenyBet = (bet, params) => {
	switch (bet.errorCode) {
		case ERROR_MESSAGES.LINE_CHANGED: {
			const betError = JSON.parse(bet.jsonString)
			let errorCode = bet.odds
				? ERROR_MESSAGES.LINE_ODDS_CHANGED
				: ERROR_MESSAGES.LINE_CHANGED
			let odds = bet.odds
			const _bet = betError?.bets?.find(
				(line) => line.uniqueRequestId === bet.uniqueRequestId,
			)
			const selection = params.selections.find(
				(_selection) => _selection.uniqueRequestId === bet.uniqueRequestId,
			)
			if (_bet && parseFloat(_bet.price) !== parseFloat(selection.odds)) {
				errorCode = ERROR_MESSAGES.LINE_ODDS_CHANGED
				odds = _bet.price
			}
			return {
				errorCode: errorCode,
				odds: odds,
				oddsId: bet.oddsId,
				selectionId: bet.selectionId,
			}
		}
		default:
			return {
				errorCode: bet.errorCode,
				odds: bet.odds,
				oddsId: bet.oddsId,
				selectionId: bet.selectionId,
			}
	}
}

export const calculateTotalWager = (oddsFormat, selections) =>
	selections.reduce(
		(result, selection) => {
			const stake =
				!selection.winRiskStake || selection.winRiskStake === WAGER_TYPE.stake
					? parseFloat(selection.wager) || 0
					: calculateRisk(
							selection.wager,
							selection.odds,
							ODDS_FORMAT[oddsFormat],
							WIN_RISK_TYPE.WIN,
							selection.betType,
					  )
			const win =
				selection.winRiskStake === WAGER_TYPE.win
					? parseFloat(selection.wager) || 0
					: calculateWin(
							selection.wager,
							selection.odds,
							ODDS_FORMAT[oddsFormat],
							WIN_RISK_TYPE.RISK,
							selection.betType,
					  )
			let hasError =
				stake > 0
					? validInputWager(
							selection.minStakeRisk,
							selection.maxStakeRisk,
							stake,
					  ) !== ''
					: false
			if (
				ODDS_FORMAT.MY === ODDS_FORMAT[oddsFormat] &&
				parseFloat(selection?.odds) < 0
			) {
				hasError =
					validInputWager(selection.minStakeWin, selection.maxStakeWin, win) !==
					''
			}
			return {
				oddsFormat,
				totalStake: result.totalStake + (hasError ? 0 : parseFloat(stake) || 0),
				totalWin: result.totalWin + (hasError ? 0 : parseFloat(win) || 0),
			}
		},
		{
			oddsFormat,
			totalStake: 0,
			totalWin: 0,
		},
	)

export const getErrorMessageTabs = (
	parlaySelections = [],
	selectionsInvalidParlay = [],
	errorCode = '',
) => {
	const hasCorrelate = parlaySelections.some(
		(selection) => selection.parlayErrorCode === ERROR_MESSAGES.CORRELATED,
	)
	if (hasCorrelate) return ERROR_MESSAGES.CORRELATED
	if (errorCode) {
		return selectionsInvalidParlay.length > 1
			? ERROR_MESSAGES.INVALID_SELECTION
			: ERROR_MESSAGES.ATTENTION
	}
}
