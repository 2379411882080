export const VIEW_PORT = {
	DESKTOP: 'DESKTOP',
	TABLET: 'TABLET',
	MOBILE: 'MOBILE',
}

export const MEDIA = {
	DESKTOP_EXTRA_LARGE: 'DESKTOP_EXTRA_LARGE', // desktop extra large
	DESKTOP: 'DESKTOP',
	TABLET: 'TABLET',
	MOBILE: 'MOBILE',
	SMALL_MOBILE: 'ES_MOBILE', // extra small
}

export const VIEWS = {
	FUTURE: 'FUTURE',
}

export const SESSION_MESSAGES = {
	MULTIPLE_LOGIN: 'MULTIPLE_LOGIN',
}

export const TENNIS_ID = 33
export const BASEBALL_ID = 3

export const ODDS_SELECTION_STORAGE_KEY = 'oddPageSelections'

export const BET_PLACED = 'betsPlaced'

export const ODDS_FORMAT_ID = ['AM', 'EU', 'HK', 'ID', 'MY']

export const ODDS_TYPE = {
	AMERICAN: 'AM',
	DECIMAL: 'EU',
	HONG_KONG: 'HK',
	INDONESIAN: 'ID',
	MALAY: 'MY',
}

export const PRECISION_MAP = {
	[ODDS_TYPE.INDONESIAN]: 2,
	[ODDS_TYPE.DECIMAL]: 3,
	default: 3,
}

export const ODDS_FORMAT = {
	American: 0,
	Decimal: 1,
	HongKong: 2,
	Indonesian: 3,
	Malay: 4,
	AM: 0,
	EU: 1,
	HK: 2,
	ID: 3,
	MY: 4,
	AMERICAN: 0,
	DECIMAL: 1,
	HONG_KONG: 2,
	HONGKONG: 2,
	INDONESIAN: 3,
	MALAY: 4,
}

export const ODDS_FORMAT_LABEL = {
	AM: 'American Odds', // American
	EU: 'Decimal Odds', // Decimal
	HK: 'HongKong Odds', // Hong Kong
	ID: 'Indonesian Odds', // Indonesian
	MY: 'Malay Odds', // Malay
}

export const ODDS_TYPE_BY_BRAND = {
	american: 'AM',
	decimal: 'EU',
	hongkong: 'HK',
	indonesian: 'ID',
	malay: 'MY',
}

export const ODDS_SELECTION_TYPE = {
	NORMAL: 'NORMAL',
	OUTRIGHT: 'OUTRIGHT',
	SPECIAL: 'SPECIALS',
}

export const BET_TYPE_ONE_COLUMN = 'MULTI_WAY_HEAD_TO_HEAD'

export const WIN_RISK_TYPE = {
	RISK: 'RISK',
	WIN: 'WIN',
}

export const BET_LOCATION_TRACKING = {
	NEW_EURO_VIEW: 'NEW_EUROPE_VIEW',
	NAVIGATION_SPORT: 'SPORTS',
	MARKET_TAB_LIVE: 'LIVE',
	MARKET_TAB_HIGHLIGHT: 'HIGHLIGHT',
	MARKET_SPECIALS: 'SPECIALS',
	MAIN: 'MAIN',
	UNKNOWN: 'UNKNOWN',
	LEAGUE: 'LEAGUE',
	MAIN_PAGE_SPORT: 'SPORT',
	MAIN_PAGE_ESPORT: 'ESPORT',
	HOME: 'HOME',
	SEARCH: 'SEARCH',
	FAVOURITES: 'FAVOURITES',
	EVENT: 'EVENT',
}

export const BET_STATUS = {
	ACCEPTED: 'ACCEPTED',
	UNAVAILABLE: 'UNAVAILABLE',
	PENDING_ACCEPTANCE: 'PENDING_ACCEPTANCE',
	PROCESSED_WITH_ERROR: 'PROCESSED_WITH_ERROR',
	INVALID_BET_ACCEPTANCE_TYPE: 'INVALID_BET_ACCEPTANCE_TYPE',
	EVENT_OFFLINE: 'EVENT_OFFLINE',
}

export const HR_BET_STATUS = {
	REJECTED: 'REJECTED',
	PRICE_CHANGED: 'PRICE_CHANGED',
	ACCEPTED: 'ACCEPTED',
	PARTIAL: 'PARTIAL',
	LIMIT_LIABILITY: 'LIMIT_LIABILITY',
	INVALID_SELECTION: 'INVALID_SELECTION',
}

export const BET_TYPE = {
	THE_1X2: 1,
	HANDICAP: 2,
	OU: 3,
	HOME_TOTALS: 4,
	AWAY_TOTALS: 5,
	MIX_PARLAY: 6,
	TEASER: 7,
	MANUAL_PLAY: 8,
	SPECIALS: 9,
	SPECIAL: 9,
	OE: 97,
	OUTRIGHT: 99,
}

export const LOGIN_STATUS = {
	INVALID_USER: 0,
	LOGIN_FAILED: -1,
	WRONG_COUNTRY: -2,
	ACCOUNT_UNVERIFIED: -3,
	ACCOUNT_SUSPENDED: -4,
	WRONG_ODDS_FORMAT: -5,
	ACCOUNT_MIGRATED_TO_PS3838: -6,
	LOGIN_FAILED_ACCOUNT_STATUS: -7,
	TRY_IN_20_MINS: '4',
	TRY_IN_FEW_MINS: '5',
	SUCCESS_WITH_STT_1: 1,
	PASSWORD_EXPIRED: 2,
	UNCHECKED_TERMS_CONDITIONS: 3,
	SUCCESS_WITH_STT_6: 6,
	MISSING_SECURE_QUESTION: 7,
	EXCEED_SESSION_TIME_LIMIT: 8,
	BLOCKED_IP_RING_FENCING: 13,
	INVALID_CAPTCHA: 15,
	INVALID_TRUST_CODE: 14,
}

export const STATUS_LOGIN_SUCCESS = [
	LOGIN_STATUS.SUCCESS_WITH_STT_1,
	LOGIN_STATUS.PASSWORD_EXPIRED,
	LOGIN_STATUS.UNCHECKED_TERMS_CONDITIONS,
	LOGIN_STATUS.SUCCESS_WITH_STT_6,
	LOGIN_STATUS.MISSING_SECURE_QUESTION,
]

export const VIEW_LAYOUT = {
	ESPORTS_HUB: 'EsportsHub',
	SPORTS_BOOK: 'SportsBook',
	ACCOUNT: 'Account',
	B2B: 'B2B',
	HOME_MOBILE: 'HomeMobile',
	WITHOUT_NAVIGATION_MOBILE: 'WithoutNavigationMobile',
}

export const IFRAME_EVENT = {
	ON_DOCUMENT_READY: 'ondocumentready',
	ON_CHANGE_FROM_PINNACLE_EAST: 'onChangeFromPinnacleEast',
	ON_SCROLL_TO_TOP: 'onscrolltotop',
}

export const IFRAME_MESSAGES = {
	REFRESH_PREFS: 'refreshPrefs',
	REDIRECT_URL_UNIFIED_ACCOUNT: 'redirectUrlUnifiedAccount',
}

export const WAGER_MYBET_MAP = {
	wagerId: 0,
	masterWagerId: 1,
	eventName: 2,
	parentEventName: 3,
	headToHead: 4,
	wagerNumber: 5,
	wagerNo: 6,
	wagerDateFm: 7,
	eventDateFm: 8,
	oddsStr: 9,
	eventDate: 10,
	status: 11,
	wagerDate: 12,
	homeTeam: 13,
	awayTeam: 14,
	selectionName: 15,
	selectionType: 16,
	handicap: 17,
	odds: 18,
	oddsFormat: 19,
	betType: 20,
	league: 21,
	stake: 22,
	sportId: 23,
	sport: 24,
	sportGroup: 25,
	currency: 26,
	homeFav: 27,
	awayFav: 28,
	score: 29,
	inPlay: 30,
	homePitcher: 31,
	awayPitcher: 32,
	homePitcherName: 33,
	awayPitcherName: 34,
	period: 35,
	cancellationStatus: 36,
	wagerSelections: 37,
	category: 38,
	unit: 39,
	winRiskStake: 40,
	toWin: 41,
	toRisk: 42,
	product: 43,
	vsHandicap: 44,
	vsSelection: 45,
	vsBetType: 46,
	vsMarketType: 47,
	vsSelectionName: 48,
	parlayMixOdds: 49,
	vsLegStatus: 50,
	vsSubType: 51,
	multiplier: 52,
	teamType: 53,
	parlayMixOddsStr: 54,
	vsBetTypeId: 55,
	vsSubTypeId: 56,
	isMoneyLine: 57,
	competitors: 58,
	vsWagerSelections: 59,
	adjOdds: 60,
	adjOddsStr: 61,
	betAcceptanceType: 62,
	settlementDate: 63,
	settlementDateFm: 64,
	dateFilterType: 65,
	relateEventBetType: 66,
	raceNumber: 67,
	trackName: 68,
	runnerNumber: 69,
	runnerName: 70,
	hrBetType: 71,
	hrMarketType: 72,
	raceDateFm: 76,
	gradingUnit: 77,
	isRejectedClosedEvent: 82,
	teaserDescription: 83,
	originalHomeTeam: 84,
}

export const SELECTION_TYPE = {
	HOME: 0,
	AWAY: 1,
	DRAW: 2,
	OVER: 3,
	UNDER: 4,
	HOME_OVER: 5,
	HOME_UNDER: 6,
	AWAY_OVER: 7,
	AWAY_UNDER: 8,
	AAAA: 9,
}

export const WAGER_SELECTION_MAP = {
	selection: 0,
	homeFav: 1,
	awayFav: 2,
	eventDateFm: 3,
	oddsStr: 4,
	scores: 5,
	sportId: 6,
	sport: 7,
	sportGroup: 8,
	league: 9,
	homeTeam: 10,
	awayTeam: 11,
	betType: 12,
	eventDate: 13,
	wagerId: 14,
	selectionType: 15,
	inplayScore: 16,
	inPlay: 17,
	odds: 18,
	handicap: 19,
	homePitcher: 20,
	awayPitcher: 21,
	homePitcherName: 22,
	awayPitcherName: 23,
	period: 24,
	eventResult: 25,
	legStatus: 26,
	teamType: 27,
	eventName: 28,
	point: 29,
	type: 30,
	altTeaser: 31,
	adjOdds: 32,
	adjOddsStr: 33,
	raceNumber: 34,
	trackName: 35,
	runnerNumber: 36,
	runnerName: 37,
	hrBetType: 38,
	hrMarketType: 39,
	trackCountry: 40,
	deductions: 41,
	scratchedName: 42,
	raceDateFm: 43,
	gradingUnit: 44,
	eventId: 45,
	betAcceptanceType: 47,
}

export const B2B_CONFIG_KEY = {
	ENABLED_OPEN_BETS_LINK: 'enableOpenBetsLink',
	ENABLED_HEADER_NAVIGATION_BAR: 'enableHeaderNavigationBar',
	SUPPORTED_ODDS_FORMAT: 'supportedOddsFormats',
	ENABLED_LANGUAGE_SELECTOR: 'enableLanguageSelector',
	ENABLED_ANNOUCEMENTS_FEATURE: 'enableAnnouncementsFeature',
	ENABLED_POST_LOGOUT_MESSAGE: 'enablePostLogoutMessage',
	BETTING_RULES_URL: 'bettingRulesUrl',
	ENABLED_POST_REDIRECT_SIGNUP_MESSAGE: 'enablePostRedirectSignupMessage',
	MENU_ITEMS: 'menuItems',
	NON_LOGIN_CURRENCY_CODE: 'nonLoginCurrencyCode',
	WALLET_TYPE: 'walletType',
	DOCUMENT_TITLE: 'documentTitle',
	SUPPORTED_LANGUAGES: 'supportedLanguages',
	DEFAULT_LANGUAGE: 'defaultLanguage',
	THEME_NAME: 'themeName',
	ENABLED_DARK_MODE_FOR_GUEST: 'enableDarkModeForGuest',
	ENABLE_LIGHT_MODE: 'enableLightMode',
	ENABLE_DARK_MODE: 'enableDarkMode',
}

export const MENU_ITEM_ENABLED_CONFIG = {
	ANNOUNCEMENTS: 'ENABLED_ANNOUCEMENTS_FEATURE',
	BETTING_RULES: 'BETTING_RULES_URL',
}

export const SelectionType = {
	0: 'HOME',
	1: 'AWAY',
	2: 'DRAW',
	3: 'OVER',
	4: 'UNDER',
	HOME: 0,
	AWAY: 1,
	DRAW: 2,
	OVER: 3,
	UNDER: 4,
	HOME_OVER: 5,
	HOME_UNDER: 6,
	AWAY_OVER: 7,
	AWAY_UNDER: 8,
	AAAA: 9,
}

export const BetType = {
	HDP: {
		value: 'handicap',
		keyTranslate: 'odd_page.handicap_header',
	},
	OU: {
		value: 'total',
		keyTranslate: 'event_detail.total',
	},
	TEAM_TOTALS: {
		value: 'team-total',
		keyTranslate: 'event_detail.team_total',
	},
	ML_1X2: {
		value: 'moneyline',
		keyTranslate: 'odd_page.money_line_header',
	},
}

export const POSITION = {
	top: 'top',
	bottom: 'bottom',
	left: 'left',
	right: 'right',
}

export const STATUS_WAGER = {
	OPEN: 'OPEN',
	PENDING: 'PENDING',
	CANCELLED: 'CANCELLED',
	REJECTED: 'REJECTED',
}

export const THEME_MODE = {
	LIGHT: 'light',
	DARK: 'dark',
}

export const DISPLAY_MODE = [THEME_MODE.LIGHT, THEME_MODE.DARK]

export const hockeyNaContainers = ['USA', 'Canada']

export const specialCharNotAllowed = /[*|":<>[\]{}`\\()',;@&$!#%^-_+=.?/]/

export const ROUTER_PROVIDER = {
	STANDARD: 'standard',
	STANDARD_ESPORTS_HUB: 'standard/esports-hub',
	ESPORTS_HUB: 'esports-hub',
}

// minWidth  = 205 + 420 + 270 + 15
export const containerSize = 915

export const paddingBetSlip = 5

export const LOCAL_STORAGE = {
	TOKEN: 'token',
	X_APP_DATA: 'x-app-data',
	DYNAMIC_PARAMETER_KEY: '_dp',
	DYNAMIC_PARAMETER_VALUE: '_dbv',
	ODD_DELAY_WARNING_VIEWED: 'oddsDelayWarningViewed',
	WS_TOKEN: 'wstoken',
}

export const DATA_NO_CACHE_STATUS = {
	UN_AUTHENTICATED: 'UNAUTHENTICATED',
	NEW_TOKEN: 'NEW_TOKEN',
	CONVERTED: 'CONVERTED',
	LOGOUT: 'LOGOUT',
}

export const AUTH_TOKEN_MESSAGE = {
	AUTHENTICATED: 'AUTHENTICATED',
	USED_TOKEN: 'USED_TOKEN',
	UNAUTHENTICATED: 'UNAUTHENTICATED',
	INVALID_TOKEN: 'INVALID_TOKEN',
	LOGIN_FAILED: 'LOGIN_FAILED',
	UNKNOWN_ERROR: 'UNKNOWN_ERROR',
	LOGIN_BLOCKED: 'LOGIN_BLOCKED',
}

export const B2B_LINK_ID = {
	BETTING_RULES: 'bettingRules',
}

export const KYC_VERIFIED = {
	EMAIL_VERIFIED: 'EMAIL_VERIFIED',
	CUSTOMER_VERIFIED: 'CUSTOMER_VERIFIED',
	LEGACY_USER: 'LEGACY_USER',
}
